<template>
  <div class="main">
    <div class="columns is-centered">
      <div class="column is-7-desktop is-9-tablet">
        <h1 class="title is-1">Frequently Asked Questions (FAQs)</h1>
        <p class="subtitle has-text-grey">By Marguitta Rodriguez, Third Year Batch Representative
        </p>
        <faqs-list></faqs-list>
      </div>
    </div>
  </div>
</template>

<script>
import FaqsList from "../components/FaqsList";
export default {
  name: "Faqs",
  components: {FaqsList}
}
</script>

<style scoped>
.main {
  padding: 6.9%;
}
</style>